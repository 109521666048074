import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    ContentChild,
    TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { STATE_MODAL } from './state.const';
import { Subscription } from 'rxjs';
import { ModalCommonService } from './modal-common.service';
import { modalAnimation } from '../../animations/modal.animation';

@Component({
    selector: 'app-modal-common',
    standalone: true,
    imports: [CommonModule],
    animations: [modalAnimation],
    templateUrl: './modal-common.component.html',
    styleUrls: ['./modal-common.component.scss'],
})
export class ModalCommonComponent implements OnInit, OnDestroy {
    @Input() id: any;
    @Input() clickOutsideClose = true;
    @Input() displayX = true;
    @Input() backgroundColor = '';
    @Input() backgroundModal = '';
    subscription = new Subscription();
    showModal = false;
    @ContentChild(TemplateRef)
    templateRef?: TemplateRef<any>;

    constructor(private modalCommonService: ModalCommonService) {}

    ngOnInit(): void {
        this.subscription = this.modalCommonService
            .addModalItem(this.id)
            .subscribe((data: any) => {
                if (data.type === STATE_MODAL.OPEN) {
                    this.openModal();
                } else if (data.type === STATE_MODAL.CLOSE) {
                    this.closeModal();
                }
            });
    }

    openModal() {
        this.showModal = true;
    }

    closeModal() {
        this.showModal = false;
    }

    clickOutside() {
        if (this.clickOutsideClose) {
            this.closeModal();
        }
    }

    ngOnDestroy() {
        this.modalCommonService.removeModalItem(this.id);
        this.subscription.unsubscribe();
    }
}
